import React from 'react';
import { graphql } from 'gatsby';

import SEO from '../../components/seo';
import Day from '../../components/weekdays/Day';

function AboutPage(data) {
  const day = 3;
  const dayColors = ['bg-faith-melon', 'bg-faith-yellow'];

  return (
    <>
      <SEO
        keywords={[
          `work-life balance`,
          `parent support`,
          `lone parent`,
          `childcare`,
          'ireland',
        ]}
        title="Wednesday's Diary Entry"
      />
      <Day day={day} post={data} dayColors={dayColors} />
    </>
  );
}
export const query = graphql`
  {
    ani1: allFile(filter: { name: { eq: "wed_1" }, ext: { eq: ".json" } }) {
      edges {
        node {
          publicURL
        }
      }
    }

    ani2: allFile(filter: { name: { eq: "wed_2" }, ext: { eq: ".json" } }) {
      edges {
        node {
          publicURL
        }
      }
    }

    ani3: allFile(filter: { name: { eq: "wed_3" }, ext: { eq: ".json" } }) {
      edges {
        node {
          publicURL
        }
      }
    }

    ani4: allFile(filter: { name: { eq: "wed_4" }, ext: { eq: ".json" } }) {
      edges {
        node {
          publicURL
        }
      }
    }
    wpPage(slug: { eq: "wednesday" }) {
      id
      sectionDay {
        section1 {
          content
          header
          explainerBody
          explainerHeading
          explainerImage {
            localFile {
              publicURL
            }
          }
          illustration {
            localFile {
              publicURL
            }
          }
        }
        section2 {
          content
          header
          illustration {
            localFile {
              publicURL
            }
          }
        }
        section3 {
          content
          header
          explainerBody
          explainerHeading
          explainerImage {
            localFile {
              publicURL
            }
          }
          illustration {
            localFile {
              publicURL
            }
          }
        }
      }
      title
      content
      featuredImage {
        node {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`;
export default AboutPage;
